import Socket from 'vue-native-websocket'
import Vue from 'vue'
import { getEnvKey } from '../utils/config'

export const initSocket = userId => {
  Vue.use(Socket, `${getEnvKey('SOCKET_URL')}?userId=${userId}`, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000
  })
}
