import Vue from 'vue'
import './utils/config'
import router from './router'
import './plugins'
import i18n from './i18n'
import './assets/scss/index.scss'
import Root from '@/Root'
import 'intersection-observer/intersection-observer'
import posthogPlugin from './plugins/posthog' // import the plugin

Vue.use(posthogPlugin)

// Disable [Vue warn]
Vue.config.silent = true

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = process.env.NODE_ENV === 'development'

new Vue({
  i18n,
  router,
  render: h => h(Root)
}).$mount('#app')
