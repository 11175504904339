import vdeux from 'vdeux'
import createStore from '../utils/createStore'
import globalErrorHandler from '../utils/globalErrorHandler'
import { getEnvKey } from '../utils/config'

export const { store } = createStore(
  getEnvKey('API_DOMAIN'),
  {},
  {
    appBaseEnv: getEnvKey('APPBASE_ENV'),
    middlewares: [globalErrorHandler],
    country: window.currentCountry
  }
)

export default vdeux(store)
