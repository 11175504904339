import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'
import VueFlashMessage from 'vue-flash-message'
import InfiniteLoading from 'vue-infinite-loading'
import device from 'vue-device-detector'
import store from './store'
import Skeleton from 'vue-loading-skeleton'
import VueLazyload from 'vue-lazyload'
import VueScrollTo from 'vue-scrollto'
import VueMoment from 'vue-moment/dist/vue-moment.es'
import VueTheMask from 'vue-the-mask'
import Meta from 'vue-meta'
import VShowSlide from 'v-show-slide'
import VueSlider from 'vue-slider-component'
import VueJsonLD from 'vue-jsonld'
import VueProgressBar from 'vue-progressbar'

import './axios'
import './socket'
import './directives'
import '../assets/boxicons/css/boxicons.min.css'
import 'vue-flash-message/dist/vue-flash-message.min.css'
import './global'
import './defaultMeta'

const options = {
  color: '#DB2357',
  thickness: '3px',
  transition: {
    speed: '1s',
    opacity: '0s',
    termination: 1000
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

if (window.currentCountry === 'NO') {
  require('moment/locale/nb')
} else {
  require('moment/locale/sv')
}

Vue.use(store)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VModal, { dialog: true })
Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 10000
  }
})
Vue.use(InfiniteLoading)
Vue.use(VueJsonLD)
Vue.use(device)
Vue.use(Skeleton)
Vue.use(VueLazyload, {
  preLoad: 1.1
})
Vue.use(VueScrollTo)
Vue.use(VueMoment)
Vue.use(VueTheMask)
Vue.use(Meta)
Vue.use(VShowSlide)
Vue.use(VueProgressBar, options)
Vue.component('VueSlider', VueSlider)
Vue.config.errorHandler = function(err, vm) {
  // eslint-disable-next-line no-console
  console.error('ERROR:', err)
  vm.$router.replace({ name: 'ErrorPage', params: { originalError: err } }).catch(() => {})
}

import './analytics'
