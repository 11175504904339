export const delivery = {
  shipping: 'shipping',
  meetup: 'meetup',
  both: 'both'
}

export const paymentMethods = {
  card: 'Credit card',
  vipps: 'Vipps'
}

export const countryCodes = [
  { name: 'Norway', dialCode: '+47', code: 'NO' },
  { name: 'Sweden', dialCode: '+46', code: 'SE' }
]

export const formats = ['paperback', 'hardcover']
