import Vue from 'vue'

export const getErrorMessage = err => {
  let errorMessage = ''
  if (err && err.data && err.data.message) {
    errorMessage = err.data.message
  } else if (err.data && err.data.error) {
    errorMessage = err.data.error
  } else if (
    err.data &&
    err.data.error &&
    err.data.error.error_fields &&
    err.data.error.error_fields.length
  ) {
    errorMessage = err.data.error.error_fields[0].message
  } else {
    errorMessage = 'An error has occurred'
  }
  return errorMessage
}

export default () => next => action => {
  if (action.type === 'GLOBAL_ERROR') {
    const err = action.payload.response || action.payload
    const message = getErrorMessage(err)
    Vue.prototype.$flashStorage.flash(message, 'error', {
      timeout: 3000
    })
  }
  return next(action)
}
