import config from './environments'
import Vue from 'vue'

const getCurrentCountry = () => {
  const url = window.location.pathname.match(/[^/?]*[^/?]/g)
  const langParam = (url && url[0]) || 'no'
  let country = 'NO'
  if (langParam.includes('se') || langParam.includes('en-se')) {
    country = 'SE'
  }
  window.currentCountry = country
  return country
}

export const getEnvKey = key => {
  const country = window.currentCountry || getCurrentCountry()
  const environment = process.env.VUE_APP_VARIABLES_ENV
  if (country) {
    return (
      (config[country] && config[country][environment] && config[country][environment][key]) || ''
    )
  } else {
    throw new Error('Not set country')
  }
}

Vue.prototype.$getEnvKey = getEnvKey
