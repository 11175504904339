import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import moment from 'moment'
import { getEnvKey } from './config'

export const PDP_BROWSE_USED = 'PDP_BROWSE_USED'
export const PDP_PAGE_VIEW = 'PDP_PAGE_VIEW'
export const SELL_THIS_BOOK_USED = 'SELL_THIS_BOOK_USED'
export const PDP_CAROUSEL_YOU_LIKE = 'PDP_CAROUSEL_YOU_LIKE'
export const USER_DATA_INIT = 'USER_DATA_INIT'
export const AMP_SESSION_START = 'AMP_SESSION_START'
export const FRONTPAGE_CAROUSEL = 'FRONTPAGE_CAROUSEL'
export const FRONTPAGE_BANNER = 'FRONTPAGE_BANNER'
export const SHOW_BLOG_ARTICLE = 'SHOW_BLOG_ARTICLE'
export const ARTICLE_CAROUSEL = 'ARTICLE_CAROUSEL'
export const ARTICLE_BANNER = 'ARTICLE_BANNER'
export const ARTICLE_CTA_BUTTONS = 'ARTICLE_CTA_BUTTONS'
export const FRONTPAGE_ARTICLE_CAROUSEL = 'FRONTPAGE_ARTICLE_CAROUSEL'
export const PDP_LEAVE_REVIEW = 'PDP_LEAVE_REVIEW'
export const LOGIN_MIGRATION_SUCCESSFULL = 'LOGIN_MIGRATION_SUCCESSFULL'
export const PDP_CLICK_OTHER_VERSION = 'PDP_CLICK_OTHER_VERSION'
export const PDP_CLICK_OTHER_VERSION_BUYBOX = 'PDP_CLICK_OTHER_VERSION_BUYBOX'
export const FRONTPAGE_SEARCH = 'FRONTPAGE_SEARCH'
export const MESSAGES_ACCEPT_REQUEST = 'MESSAGES_ACCEPT_REQUEST'
export const SELL_USED_CREATE_BOOK = 'SELL_USED_CREATE_BOOK'
export const SELL_USED_BOOK_ADDED = 'SELL_USED_BOOK_ADDED'
export const LOGIN_MIGRATION_STARTED = 'LOGIN_MIGRATION_STARTED'
export const CHECKOUT_PURCHASE = 'CHECKOUT_PURCHASE'
export const CHECKOUT_DISCOUNT_CODE = 'CHECKOUT_DISCOUNT_CODE'
export const NEW_CUSTOMER = 'NEW_CUSTOMER'
export const PURCHASE = 'PURCHASE'
export const SIGN_UP_SOCIAL_STARTED = 'SIGN_UP_SOCIAL_STARTED'
export const SIGN_UP_EMAIL_REGISTER = 'SIGN_UP_EMAIL_REGISTER'
export const SIGN_UP_EMAIL_USER_VERIFIED = 'SIGN_UP_EMAIL_USER_VERIFIED'
export const SIGN_UP_DONE = 'SIGN_UP_DONE'
export const SIGN_UP_TRACK_EVENTS = 'SIGN_UP_TRACK_EVENTS'
export const BUY_USED_PICK_SELLER = 'BUY_USED_PICK_SELLER'
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST'
export const GLOBAL_SELL_BOOK = 'GLOBAL_SELL_BOOK'
export const LOGOUT_PUSH = 'LOGOUT_PUSH'
export const INTERCOM_SHUTDOWN = 'INTERCOM_SHUTDOWN'
export const SEARCH_APPLY_SORTING = 'SEARCH_APPLY_SORTING'
export const SEARCH_APPLY_FILTER = 'SEARCH_APPLY_FILTER'
export const SEARCH_CLICK_BOOK = 'SEARCH_CLICK_BOOK'
export const SEND_REQUEST = 'SEND_REQUEST'
export const BUY_USED_SEND_REQUEST_NEW_CUSTOMER = 'BUY_USED_SEND_REQUEST_NEW_CUSTOMER'
export const REQUEST_CONVERSION = 'REQUEST_CONVERSION'
export const SUCCESS_AUTH = 'SUCCESS_AUTH'
export const AUTH_PAGE_VIEWED = 'AUTH_PAGE_VIEWED'
export const VIEW_BOOK_DETAILS = 'VIEW_BOOK_DETAILS'
export const INTERCOM_OPEN = 'INTERCOM_OPEN'
export const INTERCOM_UPDATE = 'INTERCOM_UPDATE'
export const SIGN_UP_EMAIL_STARTED = 'SIGN_UP_EMAIL_STARTED'
export const BOOK_SELLERS_CHANGE_SORTING = 'BOOK_SELLERS_CHANGE_SORTING'
export const ADD_BOOK_TO_WAITING_LIST = 'ADD_BOOK_TO_WAITING_LIST'
export const ADD_MORE_SALE = 'ADD_MORE_SALE'
export const PDP_CLICK_MORE_FROM_AUTHOR_BOOK = 'PDP_CLICK_MORE_FROM_AUTHOR_BOOK'
export const USER_PROFILE_PAGE_VIEW = 'USER_PROFILE_PAGE_VIEW'
export const USER_PROFILE_CLICK_BOOK = 'USER_PROFILE_CLICK_BOOK'
export const SELL_BOOK_RECOMMENDED_PRICE = 'SELL_BOOK_RECOMMENDED_PRICE'
export const CHECKOUT_SUCCESS_LEARN_MORE = 'CHECKOUT_SUCCESS_LEARN_MORE'
export const PDP_AUTHOR_STORE = 'PDP_AUTHOR_STORE'
export const ARTICLES_PAGE_VIEW = 'ARTICLES_PAGE_VIEW'
export const PDP_CLICK_AUTHOR = 'PDP_CLICK_AUTHOR'
export const REQUEST_SKIPPED_MORE_FROM_SELLER = 'REQUEST_SKIPPED_MORE_FROM_SELLER'
export const REQUEST_ADDED_PRODUCT = 'REQUEST_ADDED_PRODUCT'
export const REQUEST_REMOVED_PRODUCT = 'REQUEST_REMOVED_PRODUCT'
export const CHECKOUT_PAGE_VIEWED = 'CHECKOUT_PAGE_VIEWED'
export const CHECKOUT_STEP_TWO = 'CHECKOUT_STEP_TWO'
export const ELEMENT_CLICKED = 'ELEMENT_CLICKED'
export const REQUEST_CREATED = 'REQUEST_CREATED'

class EventAnalytics extends Vue {
  _queue = new Proxy([], {
    set: this.sendEvents
  })
  initLaunched = false
  categories = []
  _events = {
    [SIGN_UP_EMAIL_STARTED]: () => {},
    [INTERCOM_UPDATE]: params => {
      window.Intercom &&
        window.Intercom(
          'update',
          params || { last_request_at: parseInt(new Date().getTime() / 1000) }
        )
    },
    [INTERCOM_OPEN]: () => {
      window.Intercom && window.Intercom('show')
    },
    [VIEW_BOOK_DETAILS]: () => {},
    [SUCCESS_AUTH]: params => {
      if (params.userInfo) {
        const { firstName, lastName, createdAt, email, guid, intercomHash } = params.userInfo

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'userData',
          intercomAppId: getEnvKey('INTERCOM_APP_ID'),
          intercomUserHash: intercomHash,
          userUuid: guid,
          userEmail: email,
          userName: firstName + ' ' + lastName,
          userCreatedAt: moment(createdAt).unix()
        })
      }
    },
    [SEARCH_CLICK_BOOK]: () => {},
    [CHECKOUT_SUCCESS_LEARN_MORE]: () => {},
    [ARTICLES_PAGE_VIEW]: () => {},
    [PDP_AUTHOR_STORE]: () => {},
    [SELL_BOOK_RECOMMENDED_PRICE]: () => {},
    [USER_PROFILE_PAGE_VIEW]: () => {},
    [USER_PROFILE_CLICK_BOOK]: () => {},
    [ADD_BOOK_TO_WAITING_LIST]: () => {},
    [SEARCH_APPLY_FILTER]: () => {},
    [PDP_CLICK_OTHER_VERSION]: () => {},
    [PDP_CLICK_MORE_FROM_AUTHOR_BOOK]: () => {},
    [ADD_MORE_SALE]: () => {},
    [PDP_CLICK_AUTHOR]: () => {},
    [CHECKOUT_STEP_TWO]: () => {},
    [CHECKOUT_PAGE_VIEWED]: () => {},
    [ELEMENT_CLICKED]: () => {},
    [PDP_CLICK_OTHER_VERSION_BUYBOX]: () => {},
    [BOOK_SELLERS_CHANGE_SORTING]: () => {},
    [PDP_PAGE_VIEW]: () => {},
    [REQUEST_CONVERSION]: () => {},
    [BUY_USED_SEND_REQUEST_NEW_CUSTOMER]: () => {},
    [SEND_REQUEST]: () => {},
    [CHECKOUT_DISCOUNT_CODE]: () => {},
    [SHOW_BLOG_ARTICLE]: () => {},
    [SEARCH_APPLY_SORTING]: () => {},
    [SIGN_UP_DONE]: () => {},
    [SIGN_UP_EMAIL_USER_VERIFIED]: () => {},
    [AUTH_PAGE_VIEWED]: () => {},
    [INTERCOM_SHUTDOWN]: () => {
      window.Intercom && window.Intercom('shutdown')
    },
    [LOGOUT_PUSH]: () => {},
    [GLOBAL_SELL_BOOK]: () => {},
    [ACCEPT_REQUEST]: () => {},
    [BUY_USED_PICK_SELLER]: () => {},
    [SIGN_UP_TRACK_EVENTS]: () => {},
    [SIGN_UP_EMAIL_REGISTER]: () => {},
    [SIGN_UP_SOCIAL_STARTED]: () => {},
    [PURCHASE]: () => {},
    [NEW_CUSTOMER]: () => {},
    [CHECKOUT_PURCHASE]: () => {},
    [LOGIN_MIGRATION_STARTED]: () => {},
    [SELL_USED_BOOK_ADDED]: () => {},
    [SELL_USED_CREATE_BOOK]: () => {},
    [AMP_SESSION_START]: () => {},
    [PDP_BROWSE_USED]: () => {},
    [SELL_THIS_BOOK_USED]: () => {},
    [PDP_CAROUSEL_YOU_LIKE]: () => {},
    [USER_DATA_INIT]: () => {},
    [FRONTPAGE_CAROUSEL]: () => {},
    [FRONTPAGE_BANNER]: () => {},
    [FRONTPAGE_ARTICLE_CAROUSEL]: () => {},
    [ARTICLE_CAROUSEL]: () => {},
    [ARTICLE_BANNER]: () => {},
    [ARTICLE_CTA_BUTTONS]: () => {},
    [PDP_LEAVE_REVIEW]: () => {},
    [LOGIN_MIGRATION_SUCCESSFULL]: () => {},
    [FRONTPAGE_SEARCH]: () => {},
    [MESSAGES_ACCEPT_REQUEST]: () => {},
    [REQUEST_SKIPPED_MORE_FROM_SELLER]: () => {},
    [REQUEST_ADDED_PRODUCT]: () => {},
    [REQUEST_REMOVED_PRODUCT]: () => {},
    [REQUEST_CREATED]: () => {}
  }
  emit(name, params) {
    if (this._events[name]) {
      if (this.checkAnalyticsReady()) {
        this._events[name](params)
      } else {
        this._queue = [...this._queue, () => this._events[name](params)]
      }
    }
  }
  sendEvents() {
    if (this.checkAnalyticsReady()) {
      this._queue.map(fn => fn())
      this._queue = []
    }
  }
  initGtag(userInfo) {
    const gtmId = 'GTM-WZ6FHJGB'
    // const gtmDevEnv = {
    //   queryParams: {
    //     gtm_auth: 'NWr5nVgzAe8vX2vhkLowbQ',
    //     gtm_preview: 'env-21',
    //     gtm_cookies_win: 'x'
    //   }
    // }
    Vue.use(VueGtm, {
      id: gtmId,
      enabled: true,
      debug: false,
      vueRouter: this.$router
    })

    if (userInfo) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'userData',
        intercomAppId: getEnvKey('INTERCOM_APP_ID'),
        intercomUserHash: userInfo.intercomHash,
        userUuid: userInfo.guid,
        userEmail: userInfo.email,
        userName: userInfo.firstName + ' ' + userInfo.lastName,
        userCreatedAt: moment(userInfo.createdAt).unix()
      })
    } else {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'initIntercom',
        intercomAppId: getEnvKey('INTERCOM_APP_ID')
      })
    }

    return Promise.resolve()
  }
  initPostHog(userInfo) {
    try {
      window.CookieInformation = window.CookieInformation || null
      if (window.CookieInformation) {
        const trackingPermissions = window.CookieInformation?.getStatusOfUsedConsentTypes()
        if (trackingPermissions && trackingPermissions.cookie_cat_statistics) {
          console.log('opt_out_capturing')
          this.$posthog.opt_in_capturing()
          this.$posthog.identify(userInfo.id, {
            email: userInfo.email
          })
        } else {
          console.log('opt_out_capturing')
          this.$posthog.opt_out_capturing()
        }
      }
    } catch (error) {
      console.error('Error initializing PostHog', error)
    }
  }
  initAnalytics(userInfo) {
    this.initLaunched = true
    const scripts = []
    scripts.push([this.initGtag(userInfo), this.initPostHog(userInfo)])
  }
  checkAnalyticsReady() {
    return true
  }
}

export default EventAnalytics
