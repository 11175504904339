import Vue from 'vue'
import PhoneNumber from 'awesome-phonenumber'

import * as constants from '../utils/constants.js'
import i18n from '../i18n'

Vue.prototype.$constants = constants
Vue.prototype.$formatPrice = function(price) {
  return i18n.t('price', { price })
}

Vue.prototype.$scroll = {
  scrollPosition: 0,
  lock() {
    this.scrollPosition = window.pageYOffset
    document.body.style.top = `${-this.scrollPosition}px`
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
  },
  unlock() {
    document.body.style.removeProperty('top')
    document.body.style.removeProperty('overflow')
    document.body.style.removeProperty('position')
    if (this.scrollPosition) {
      window.scrollTo(0, this.scrollPosition)
      this.scrollPosition = 0
    }
  }
}

Vue.prototype.$parsePhoneNumber = phoneNumber => {
  return new Promise((resolve, reject) => {
    if (!phoneNumber) return reject('Phone number is required')
    let formattedPhone = phoneNumber.startsWith('00') ? phoneNumber.replace('00', '+') : phoneNumber
    const pn = new PhoneNumber(formattedPhone)
    const countryCode = `+${pn.getCountryCode()}`
    const phone = pn.getNumber('significant')
    if (!countryCode || !phone) return reject(`Phone number "${phoneNumber}" isn't valid`)
    return resolve({
      countryCode,
      phone,
      isValid: pn.isValid(),
      region: pn.getRegionCode()
    })
  })
}
