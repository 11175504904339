import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import no from '@/locales/no.json'
import sv from '@/locales/sv.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: {
    en,
    no,
    sv
  }
})

export default i18n
