import createStore from '@bookis/store'

export default (apiUrl, initState, config = {}) => {
  const { store, runSaga } = createStore(apiUrl, 'web', config, initState)

  store.dispatchAsync = action => {
    return new Promise((resolve, reject) => {
      if (action.payload.callback) {
        const successCallback = action.payload.callback.success
        const errorCallback = action.payload.callback.error
        action.payload.callback.success = data => {
          if (successCallback) successCallback(data)
          resolve(data)
        }
        action.payload.callback.error = e => {
          if (errorCallback) errorCallback(e)
          reject(e)
        }
      } else {
        action.payload.callback = {
          success: data => {
            resolve(data)
          },
          error(e) {
            store.dispatch({ type: 'GLOBAL_ERROR', payload: e })
            reject(e)
          }
        }
      }
      store.dispatch(action)
    })
  }

  return { store, runSaga }
}
