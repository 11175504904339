//./plugins/posthog.js
import posthog from 'posthog-js'

export default {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog.init('phc_akVKPjixMwrs5jcsc9F549iD9JqOWnjkVNOIPRv3Jif', {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only'
    })
  }
}
