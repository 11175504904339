import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@/plugins/store'
import {
  setLocale,
  setRegion,
  getBookByIsbn,
  setAuthToken,
  fetchUser,
  userLogout
} from '@bookis/store'
import { initSocket } from '@/plugins/socket'
import { getEnvKey } from '../utils/config'
import VueCookies from 'vue-cookies'
import { INTERCOM_UPDATE } from '../utils/events'

const App = () => import(/* webpackChunkName: "mainApp" */ '../App.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../pages/index.vue')
const Auth = () => import(/* webpackChunkName: "auth" */ '../pages/auth.vue')
// const BookDetails = () => import(/* webpackChunkName: "bookDetails" */ '../pages/book-details')
// const BookSellers = () => import(/* webpackChunkName: "bookSellers" */ '../pages/book-sellers')
const Request = () => import(/* webpackChunkName: "request" */ '../pages/request')
const SearchBooks = () => import(/* webpackChunkName: "searchBooks" */ '../pages/search-books')
const SearchGenre = () => import(/* webpackChunkName: "searchGenre" */ '../pages/search-genre')
const SearchUsers = () => import(/* webpackChunkName: "searchUsers" */ '../pages/search-users.vue')
const AddSale = () => import(/* webpackChunkName: "addSale" */ '../pages/add-sale.vue')
const BuyUsed = () => import(/* webpackChunkName: "buyUsed" */ '../pages/buy-used.vue')
const BecomeSeller = () =>
  import(/* webpackChunkName: "becomeSeller" */ '../pages/become-seller.vue')
const ErrorPage = () => import(/* webpackChunkName: "errorPage" */ '../pages/error-page')
const UserProfile = () => import(/* webpackChunkName: "userProfile" */ '../pages/user-profile.vue')
const Pages = () => import(/* webpackChunkName: "pages" */ '../pages/static/index.vue')
const StaticPageContent = () =>
  import(/* webpackChunkName: "pages" */ '../components/StaticPages/PageContent')
const Welcome = () => import(/* webpackChunkName: "welcome" */ '../pages/welcome.vue')
const RoyaltiesAuthor = () =>
  import(/* webpackChunkName: "royaltiesAuthor" */ '../pages/royalties-author.vue')
const RoyaltiesCustomer = () =>
  import(/* webpackChunkName: "royaltiesCustomer" */ '../pages/royalties-customer.vue')
const Categories = () => import(/* webpackChunkName: "categories" */ '../pages/categories.vue')
const ListDetails = () => import(/* webpackChunkName: "listDetails" */ '../pages/list-details.vue')
const BlogArticle = () =>
  import(/* webpackChunkName: "blogArticle" */ '../pages/blog/blog-article.vue')
const Blog = () => import(/* webpackChunkName: "blog" */ '../pages/blog/blog.vue')
const BlogArticles = () => import(/* webpackChunkName: "blog" */ '../pages/blog/articles.vue')
const RequestTrack = () => import(/* webpackChunkName: "requestTrack" */ '../pages/request-track')
const Messages = () => import(/* webpackChunkName: "messages" */ '../pages/my-zone/messages')
const Profile = () => import(/* webpackChunkName: "profile" */ '../pages/profile')
const Sales = () => import(/* webpackChunkName: "sales" */ '../pages/profile/sales')
const Royalties = () =>
  import(/* webpackChunkName: "royalties" */ '../pages/profile/royalties/index')
const RoyaltiesPayout = () =>
  import(/* webpackChunkName: "royalties" */ '../pages/profile/royalties/payout')
const Purchases = () => import(/* webpackChunkName: "purchases" */ '../pages/profile/purchases')
const Lists = () => import(/* webpackChunkName: "lists" */ '../pages/profile/lists')
const ProfileListDetails = () =>
  import(/* webpackChunkName: "listsDetails" */ '../pages/profile/lists/list-details')
const Settings = () => import(/* webpackChunkName: "settings" */ '../pages/profile/settings')
const AccountSettings = () =>
  import(/* webpackChunkName: "accountSettings" */ '../pages/profile/settings/account')
const SellerSettings = () =>
  import(/* webpackChunkName: "sellerSettings" */ '../pages/profile/settings/seller')
const AddressesSettings = () =>
  import(/* webpackChunkName: "addressesSettings" */ '../pages/profile/settings/addresses')
const PaymentsSettings = () =>
  import(/* webpackChunkName: "paymentsSettings" */ '../pages/profile/settings/payments')
const NotificationsSettings = () =>
  import(/* webpackChunkName: "notifications" */ '../pages/profile/settings/notifications')
const Payout = () =>
  import(/* webpackChunkName: "payout" */ '../pages/profile/settings/seller/payout')
const Payment = () =>
  import(/* webpackChunkName: "payment" */ '../pages/profile/settings/payments/payment')
const Address = () =>
  import(/* webpackChunkName: "address" */ '../pages/profile/settings/addresses/address')
const RequestTrackDetails = () =>
  import(/* webpackChunkName: "trackDetails" */ '../pages/track-details')
const AuthorDetails = () => import(/* webpackChunkName: "authorDetails" */ '../pages/author')
const SeriesDetails = () =>
  import(/* webpackChunkName: "seriesDetails" */ '../pages/author/series-details')
const AuthorVerification = () =>
  import(/* webpackChunkName: "seriesDetails" */ '../pages/author/verification')
const Voting = () => import(/* webpackChunkName: "seriesDetails" */ '../pages/voting')
const VotingUnread = () => import(/* webpackChunkName: "seriesDetails" */ '../pages/voting/unread')
Vue.use(VueRouter)

const checkAuth = ({ name, query, params }, from, next) => {
  const { user } = store.getState()
  if (user && user.token) return next()
  return next({ name: 'Auth', query: { nextPage: JSON.stringify({ name, query, params }) } })
}

const routes = [
  {
    path: '/',
    name: 'root',
    redirect() {
      const savedLanguage = Vue.$cookies.get(`locale${getEnvKey('COOKIE_SUFFIX')}`)
      return savedLanguage && savedLanguage !== getEnvKey('DEFAULT_COUNTRY_LANGUAGE')
        ? `${savedLanguage}-${window.currentCountry.toLowerCase()}`
        : window.currentCountry.toLowerCase()
    }
  },
  {
    path: '/:lang',
    component: App,
    beforeEnter(to, from, next) {
      const savedLanguage = Vue.$cookies.get(`locale${getEnvKey('COOKIE_SUFFIX')}`)
      const validLangParam =
        savedLanguage && savedLanguage !== getEnvKey('DEFAULT_COUNTRY_LANGUAGE')
          ? `${savedLanguage}-${window.currentCountry.toLowerCase()}`
          : window.currentCountry.toLowerCase()
      const { lang } = to.params
      const locale = savedLanguage || getEnvKey('DEFAULT_COUNTRY_LANGUAGE')
      store.dispatch(setLocale(locale))
      store.dispatch(setRegion(window.currentCountry.toLowerCase()))
      if (lang !== validLangParam) {
        next({
          ...to,
          params: {
            ...to.params,
            lang: validLangParam
          },
          replace: true
        })
      } else {
        next()
      }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter(to) {
          window.location.href = to.fullPath
        }
      },
      {
        path: 'i/:isbn',
        name: 'ISBNSearch',
        beforeEnter(to, from, next) {
          const { isbn } = to.params
          store
            .dispatchAsync(getBookByIsbn({ isbn }))
            .then(book => {
              next({ name: 'BookDetails', params: { slug: book.slug } })
            })
            .catch(() => {
              next({ name: 'Home' })
            })
        }
      },
      {
        path: 'author-verification',
        component: AuthorVerification,
        name: 'AuthorVerification'
      },
      {
        path: 'authors/:id',
        component: AuthorDetails,
        name: 'AuthorDetails'
      },
      {
        path: 'authors/:id/:seriesTitle',
        component: SeriesDetails,
        name: 'SeriesDetails'
      },
      {
        path: 'auth',
        component: Auth,
        name: 'Auth'
      },
      {
        path: 'my-zone/messages',
        name: 'Messages',
        component: Messages,
        beforeEnter: checkAuth
      },
      {
        path: 'profile/royalties/payout',
        name: 'RoyaltiesPayout',
        component: RoyaltiesPayout,
        beforeEnter(to, from, next) {
          const { user } = store.getState()
          if (user && user.userInfo && user.userInfo.isVerifiedAuthor) return next()
          return next({
            name: 'Sales'
          })
        }
      },
      {
        path: 'profile/settings/addresses/add',
        name: 'AddAddress',
        beforeEnter: checkAuth,
        component: Address
      },
      {
        path: 'profile/settings/addresses/:id/edit',
        name: 'EditAddress',
        beforeEnter: checkAuth,
        component: Address
      },
      {
        path: 'profile/settings/seller/payout/add',
        name: 'AddPayout',
        beforeEnter: checkAuth,
        component: Payout
      },
      {
        path: 'profile/settings/seller/payout/:id/edit',
        name: 'EditPayout',
        beforeEnter: checkAuth,
        component: Payout
      },
      {
        path: 'profile/settings/payments/add',
        name: 'AddPayment',
        beforeEnter: checkAuth,
        component: Payment
      },
      {
        path: 'profile/settings/payments/:id/edit',
        name: 'EditPayment',
        beforeEnter: checkAuth,
        component: Payment
      },
      {
        path: 'profile/lists/:id',
        name: 'ProfileListDetails',
        component: ProfileListDetails
      },
      {
        path: 'profile/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: checkAuth,
        redirect() {
          return { name: 'AccountSetting' }
        },
        children: [
          {
            path: 'account',
            name: 'AccountSetting',
            component: AccountSettings
          },
          {
            path: 'seller',
            name: 'SellerSettings',
            component: SellerSettings
          },
          {
            path: 'addresses',
            name: 'AddressesSettings',
            component: AddressesSettings
          },
          {
            path: 'payments',
            name: 'PaymentsSettings',
            component: PaymentsSettings
          },
          {
            path: 'notifications',
            name: 'Notifications',
            component: NotificationsSettings
          }
        ]
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: checkAuth,
        redirect() {
          return { name: 'Sales' }
        },
        children: [
          {
            path: 'sales',
            name: 'Sales',
            component: Sales
          },
          {
            path: 'purchases',
            name: 'Purchases',
            component: Purchases
          },
          {
            path: 'lists',
            name: 'Lists',
            component: Lists
          },
          {
            path: 'royalties',
            name: 'Royalties',
            component: Royalties,
            beforeEnter(to, from, next) {
              const { user } = store.getState()
              if (user && user.userInfo && user.userInfo.isVerifiedAuthor) return next()
              return next({
                name: 'Sales'
              })
            }
          }
        ]
      },
      {
        path: 'voting',
        name: 'Voting',
        component: Voting,
        beforeEnter: checkAuth,
        children: [
          {
            path: 'unread',
            name: 'VotingUnread',
            component: VotingUnread
          }
        ]
      },
      {
        path: 'books/:slug',
        beforeEnter(to) {
          window.location.href = to.fullPath
        },
        name: 'BookDetails'
      },
      {
        path: 'b',
        component: Blog,
        children: [
          {
            path: 'category/:category',
            component: BlogArticles,
            name: 'BlogCategory'
          },
          {
            path: '/',
            component: BlogArticles,
            name: 'Blog'
          }
        ]
      },
      {
        path: 'b/s',
        component: Blog,
        children: [
          { path: '/', component: BlogArticles, name: 'Scribents' },
          {
            path: 'category/:category',
            component: BlogArticles,
            name: 'ScribentsCategory'
          }
        ]
      },
      {
        path: 'b/:slug',
        component: BlogArticle,
        name: 'BlogArticle'
      },
      {
        path: 'books/:slug/sellers',
        beforeEnter(to) {
          window.location.href = to.fullPath
        },
        name: 'BookSellers'
      },
      {
        path: 'explore',
        beforeEnter(to) {
          window.location.href = to.fullPath
        },
        name: 'ExploreCategories'
      },
      {
        path: 'request/track/:track',
        beforeEnter(from) {
          // TODO: Fix
          // const baseUrl = getEnvKey('BASE_URL')
          window.location.href = `https://bookis.com/${from.params.lang}/request/track`
        },
        name: 'RequestTrackDetails'
      },
      {
        path: 'request/track',
        component: RequestTrack,
        name: 'RequestTrack'
      },
      {
        path: 'request',
        component: Request,
        name: 'Request'
      },
      {
        path: 'search',
        name: 'SearchBooks',
        beforeEnter(to) {
          window.location.href = to.fullPath
        }
      },
      {
        path: 'search/users',
        component: SearchUsers,
        name: 'SearchUsers'
      },
      {
        path: 'search/:genreSlug',
        component: SearchGenre,
        name: 'GenreSearchBooks'
      },
      {
        path: 'categories',
        component: Categories,
        name: 'Categories'
      },
      {
        path: 'add-sale',
        component: AddSale,
        name: 'AddSale'
      },
      {
        path: 'become-seller',
        component: BecomeSeller,
        name: 'BecomeSeller'
      },
      {
        path: 'buy-used',
        component: BuyUsed,
        name: 'BuyUsed'
      },
      {
        path: 'community/user/:id/profile',
        component: UserProfile,
        name: 'UserProfile'
      },
      {
        path: 'community/user/:id/list/:listId',
        component: ListDetails,
        name: 'ListDetails'
      },
      {
        path: 'pages',
        component: Pages,
        children: [
          {
            path: ':page',
            component: StaticPageContent,
            name: 'StaticPage'
          }
        ]
      },
      {
        path: 'welcome',
        component: Welcome,
        name: 'Welcome'
      },
      {
        path: 'royalties-author',
        component: RoyaltiesAuthor,
        name: 'RoyaltiesAuthor'
      },
      {
        path: 'royalties-customer',
        component: RoyaltiesCustomer,
        name: 'RoyaltiesCustomer'
      },
      {
        path: 'logout',
        component: {
          created() {
            this.$cookies.remove(
              `token${this.$getEnvKey('COOKIE_SUFFIX')}`,
              '/',
              this.$getEnvKey('COOKIE_DOMAIN')
            )
            window.location.href = `${window.location.origin}/${this.$route.params.lang}`
          }
        },
        name: 'Logout'
      }
    ]
  },
  {
    path: '/error',
    component: ErrorPage,
    name: 'ErrorPage'
  },
  {
    path: '*',
    redirect: '/error'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (
        (from.name?.includes('Scribents') && to.name?.includes('Scribents')) ||
        (from.name?.includes('Blog') && to.name?.includes('Blog'))
      ) {
        return null
      }
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const token = VueCookies.get(`token${getEnvKey('COOKIE_SUFFIX')}`)
  const { user } = store.getState()

  const logoutUser = () => {
    VueCookies.remove(
      `token${Vue.prototype.$getEnvKey('COOKIE_SUFFIX')}`,
      '/',
      Vue.prototype.$getEnvKey('COOKIE_DOMAIN')
    )
    store.dispatch(userLogout())
    next({ name: 'Home' })
  }

  if (token) {
    if (user && user.token) {
      if (
        user.userInfo &&
        user.userInfo.role === 'guest' &&
        (to.name !== 'Checkout' || to.name !== 'CheckoutStatus')
      ) {
        return logoutUser()
      }
      return next()
    } else {
      store.dispatch(setAuthToken(token))
      store.dispatchAsync(
        fetchUser({
          success({ id }) {
            initSocket(id)
            return next()
          },
          error() {
            return logoutUser()
          }
        })
      )
    }
  } else {
    return next()
  }
})

router.afterEach((to, from) => {
  Vue.prototype.$analytics.emit(INTERCOM_UPDATE)
  const html = document.querySelector('html')
  if (to.name !== 'Messages' && from.name === 'Messages') {
    html.classList.remove('lock-scroll-chat')
  }
  Vue.prototype.$scroll.unlock()
})

export default router
