import Vue from 'vue'

Vue.prototype.$getDefaultMeta = function() {
  return {
    title: this.$t('meta.all.title'),
    meta: [
      { property: 'og:title', content: this.$t('meta.all.title') },
      {
        hid: 'description',
        name: 'description',
        content: this.$t('meta.all.description')
      },
      { property: 'og:type', content: 'website' },
      { property: 'keywords', content: this.$t('meta.all.keywords') },
      {
        property: 'og:description',
        content: this.$t('meta.all.description')
      },
      {
        property: 'og:url',
        content: `${this.$getEnvKey('BASE_URL')}${this.$route.path}`
      },
      {
        property: 'og:image',
        content: `${this.$getEnvKey(
          'BASE_URL'
        )}assets/images/share-image-${window.currentCountry.toLowerCase()}.jpg`
      }
    ]
  }
}
